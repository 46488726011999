export const translationKeys = {
  faxConfiguration: "faxConfiguration",
  advancedOptions: "advancedOptions",
  priority: "priority",
  urgent: "urgent",
  normal: "normal",
  resolution: "resolution",
  standard: "standard",
  fine: "fine",
  toLabel: "toLabel",
  toPlaceholder: "toPlaceholder",
  ccLabel: "ccLabel",
  ccPlaceholder: "ccPlaceholder",
  scheduleFax: "scheduleFax",
  selectHour: "selectHour",
  selectDate: "selectDate",
  sendReportTo: "sendReportTo",
  sendReportToPlaceholder: "sendReportToPlaceholder",
  sendReportToAdditional: "sendReportToAdditional",
  sendReportToAdditionalPlaceholder: "sendReportToAdditionalPlaceholder",
  copyOfFax: "copyOfFax",
  onlyAttachments: "onlyAttachments",
  addReceiverList: "addReceiverList",
  uploadButtonLabel: "uploadButtonLabel",
  resetOptionsButton: "resetOptionsButton",
  useSemiColonsError: "useSemiColonsError",
  onlyNumbersError: "onlyNumbersError",
  validEmailAddressesError: "validEmailAddressesError",
  onlyTxtFilesError: "onlyTxtFilesError",
  failToSetToError: "failToSetToError",
  failToSetCcError: "failToSetCcError",
  optionsSetSuccessfully: "optionsSetSuccessfully",
  sendFaxTo: "sendFaxTo",
  includeCc: "includeCc",
  statusReportTo: "statusReportTo",
  statusReportToAdditional: "statusReportToAdditional",
  receiveCopyOfFax: "receiveCopyOfFax",
  sendOnlyAttachments: "sendOnlyAttachments",
  receiversList: "receiversList",
  scheduledDate: "scheduledDate",
  modifyOptionsButton: "modifyOptionsButton",
  noRecipient: "noRecipient",
  yes: "yes",
  no: "no",
  none: "none",
  noDate: "noDate",
  coverPageLabel: "coverPageLabel",
  coverPagePlaceholder: "coverPagePlaceholder",
  costCenterLabel: "costCenterLabel",
  costCenterPlaceholder: "costCenterPlaceholder",
  tsidLabel: "tsidLabel",
  tsidPlaceholder: "tsidPlaceholder",
  tsidLengthError: "tsidLengthError",
  noCoverPage: "noCoverPage",
  noCostCenter: "noCostCenter",
  noTsid: "notsid",
  faxDetails: "faxDetails",
  sender: "sender",
  faxNumber: "faxNumber",
  subject: "subject",
  body: "body",
  attachments: "attachments",
  emptyFileError: "emptyFileError",
  reply: "reply",
  createNewFax: "createNewFax",
  tokenError: "tokenError",
  internalServerError: "internalServerError",
  loadingInfo: "loadingInfo",
  errorPageButtonText: "errorPageButtonText",
  errorPageWaitingButtonText: "errorPageWaitingButtonText",
  internetHeadersError: "internetHeadersError",
  attachmentsError: "attachmentsError",
};
