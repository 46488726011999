import { EmailAddressDetails, IConfigurations } from "types";
import { translationKeys as keys } from "translations/keys";
import { MessageBarType } from "@fluentui/react";
import { CUSTOM_HEADERS_KEYS_MAPPER, MAX_TSID_LENGTH } from "constants/index";

export function extractAddresses(address: string) {
  return address.includes(";")
    ? address
        .split(";")
        .map((address: string) => address.trim())
        .map((address: string) => {
          return address.replace(/\+/g, "");
        })
        .filter((address: string) => address)
    : [address.trim().replace(/\+/g, "")];
}

export function parseFaxNumbers(numbers: string, sufix: string) {
  if (!numbers) return [];
  const numbersArray = extractAddresses(numbers);
  const parsedFaxNumbers = numbersArray.map((number) => "+" + number + sufix);
  return parsedFaxNumbers;
}

export const parseEmailAddresses = (emails: string) => {
  const emailsArray = extractAddresses(emails);
  let fieldReceivers = "";
  emailsArray.forEach((receiver: any) => {
    if (!receiver) return;
    fieldReceivers += receiver.trim().replace(" ", "") + "; ";
  });
  return fieldReceivers;
};

export function validateOnlyNumbers(email: string) {
  if (!email) return true;
  const testEmail = email.trim().replace(/;/g, "1").replace(/\+/g, "");
  return /^[0-9]+$/.test(testEmail);
}

export function validateEmail(name: string, emails: string) {
  let validation: string = "";
  if (!emails) return validation;

  if (emails.includes(",")) {
    return (validation = keys.useSemiColonsError);
  }
  const emailToValidate = extractAddresses(emails);
  for (let i = 0; i < emailToValidate.length; i++) {
    if (name === "to" || name === "cc") {
      const hasOnlyNumbers = validateOnlyNumbers(emailToValidate[i]);
      if (!hasOnlyNumbers) {
        return (validation = keys.onlyNumbersError);
      } else {
        validation = "";
      }
    } else {
      if (
        !emailToValidate[i] ||
        !emailToValidate[i].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
      ) {
        return (validation = keys.validEmailAddressesError);
      } else {
        validation = "";
      }
    }
  }
  return validation;
}

export function validateTsid(tsid: string) {
  if (!tsid) return "";
  if (tsid.trim().length > MAX_TSID_LENGTH) {
    return keys.tsidLengthError;
  }
  return "";
}

export function setReceiverAddress(
  receivers: string,
  name: string,
  sufix: string
) {
  const recipients = parseFaxNumbers(receivers, sufix);
  if (name === "to") {
    Office.context.mailbox.item?.to.setAsync([...recipients], (result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        console.log("failed to set to");
        return keys.failToSetToError;
      }
    });
  }
  if (name === "cc") {
    Office.context.mailbox.item?.cc.setAsync([...recipients], (result) => {
      if (result.status === Office.AsyncResultStatus.Failed) {
        console.log("failed to set cc");
        return keys.failToSetCcError;
      }
    });
  }
}

export function parseReceiverFaxNumber(receivers: EmailAddressDetails[]) {
  let fieldReceivers = "";
  receivers.forEach((receiver: any) => {
    fieldReceivers +=
      receiver.emailAddress.replace("+", "").split("@")[0] + "; ";
  });
  return fieldReceivers;
}

export const fileToBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export async function handleFile(event: any, fileName: string) {
  const newFile = event.target.files[0];
  if (newFile.size <= 0) {
    return {
      name: fileName,
      content: "",
      error: keys.emptyFileError,
    };
  }
  const fileExtension =
    newFile.name.split(".")[newFile.name.split(".").length - 1];
  if (newFile.type !== "text/plain" || fileExtension !== "txt") {
    return {
      name: fileName,
      content: "",
      error: keys.onlyTxtFilesError,
    };
  }

  try {
    const base64 = (await fileToBase64(newFile)) as string;
    const content = base64.split(",")[1];
    return { name: newFile.name, content: content, error: "" };
  } catch (error: any) {
    console.log("error", error.message);
    return {
      name: fileName,
      content: "",
      error: error.message,
    };
  }
}

export function parseDateAndTime(date: string, hour: string | undefined) {
  const dateToParse = new Date(date);
  const timeDifference = dateToParse.getTimezoneOffset();
  let dateWithTimeDifference;
  if (timeDifference < 0) {
    dateWithTimeDifference = new Date(
      dateToParse.setMinutes(dateToParse.getMinutes() - timeDifference)
    );
  } else {
    dateWithTimeDifference = new Date(
      dateToParse.setMinutes(dateToParse.getMinutes() + timeDifference)
    );
  }
  const dateToSend = new Date(dateWithTimeDifference)
    .toISOString()
    .split("T")[0];
  const timeZone = new Date(date).toString().split("GMT")[1].split(" ")[0];
  const dateAndHourToSend = dateToSend + "-" + hour + timeZone;
  return dateAndHourToSend;
}

export function setNotification(
  type: MessageBarType,
  message: string,
  callBack: React.Dispatch<any>
) {
  setTimeout(() => {
    return callBack({ type: type, message: "" });
  }, 5000);
  return callBack({ type: type, message: message });
}

export function processHeaderValues(e: any, headerValue?: string) {
  let key = "";
  const { name, id } = e.target as HTMLInputElement;
  let { value } = e.target as HTMLInputElement;

  if (headerValue) value = headerValue;
  if (name) {
    key =
      CUSTOM_HEADERS_KEYS_MAPPER[
        name as keyof typeof CUSTOM_HEADERS_KEYS_MAPPER
      ];
  } else if (id) {
    key =
      CUSTOM_HEADERS_KEYS_MAPPER[id as keyof typeof CUSTOM_HEADERS_KEYS_MAPPER];
  }
  return { key, value };
}

export function removeCustomHeadersValues(key: string, headers: any) {
  const newCustomHeadersValues = { ...headers };
  const keyToRemove =
    CUSTOM_HEADERS_KEYS_MAPPER[key as keyof typeof CUSTOM_HEADERS_KEYS_MAPPER];
  delete newCustomHeadersValues[keyToRemove as keyof typeof headers];
  return newCustomHeadersValues;
}

// export function parseViewConfigurations(configurations: any) {
//   const parsedConfigurations = {
//     ...configurations,
//     customHeaders: JSON.parse(configurations.customHeaders),
//   };
//   return parsedConfigurations;
// }

export const parseDropDownOptions = (options: any, canSuppress?: boolean) => {
  const parsedOptions = options.map((option: any) => {
    return {
      key: option.id,
      text: option.displayName,
    };
  });
  if (canSuppress) parsedOptions.unshift({ key: "suppress", text: "Suppress" });
  return parsedOptions;
};

export function parseConfigurations(configurations: any): IConfigurations {
  return {
    fieldValues: {
      reportTo: {
        value: configurations.statusMailTo.value || "",
        error: "",
      },
      additionalReport: {
        value: configurations.statusMailCC.value || "",
        error: "",
      },
      tsid: {
        value: configurations.tsid.value || "",
        error: "",
      },
    },
    checkBoxValues: {
      faxCopy: configurations.sndStatusMail.value || false,
      onlyAtt: configurations.attOnly.value || false,
      addReceiverList: false,
      date: false,
      coverPage: configurations.coverPage.value ? true : false,
      costCenter: configurations.costCenter.value ? true : false,
    },
    dropdownOptions: {
      coverPage: configurations.coverPage.display
        ? parseDropDownOptions(
            configurations.coverPage.options,
            configurations.coverPage.canSuppress
          )
        : [],
      costCenter: configurations.costCenter.display
        ? parseDropDownOptions(
            configurations.costCenter.options,
            configurations.costCenter.canSuppress
          )
        : [],
    },
    dropdownSelectedValues: {
      coverPage: configurations.coverPage.value || "",
      costCenter: configurations.costCenter.value || "",
      hour: 0,
    },
    sufix: "@" + configurations.faxGatewayDomain || "@eu.rfax.net",
    priority: configurations.priority.value || "",
    resolution: configurations.resolution.value || "",
  };
}
