import {
  Image,
  IStackStyles,
  IStackTokens,
  PrimaryButton,
  Stack,
  Text,
  Spinner,
  SpinnerSize,
} from "@fluentui/react";
import { useEffect, useState } from "react";

const containerStackStyles: Partial<IStackStyles> = {
  root: {
    padding: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "100vh",
  },
};
const stackTokens: IStackTokens = { childrenGap: 6 };

type ErrorPageProps = {
  message: string | undefined;
  retry?: boolean | undefined;
  buttonText: string;
  waitingButtonText: string;
};

function ErrorPage({
  message,
  retry,
  buttonText,
  waitingButtonText,
}: ErrorPageProps) {
  const [timerReady, setTimerReady] = useState(true);

  function startTimer() {
    setTimerReady(false);
    setTimeout(() => {
      setTimerReady(true);
    }, 8000);
  }
  useEffect(() => {
    startTimer();
  }, []);

  return (
    <Stack tokens={stackTokens} styles={containerStackStyles}>
      <Image src='logo.png' width={100} height={100} />
      <Text style={{ textAlign: "center" }}>{message}</Text>
      {retry && (
        <PrimaryButton
          style={{ marginTop: "1rem", minWidth: "200px" }}
          disabled={!timerReady}
          onClick={() => {
            window.location.reload();
          }}>
          {timerReady ? (
            buttonText
          ) : (
            <>
              {waitingButtonText}
              <Spinner
                size={SpinnerSize.small}
                style={{ marginLeft: "0.5rem" }}
              />
            </>
          )}
        </PrimaryButton>
      )}
    </Stack>
  );
}

export default ErrorPage;
