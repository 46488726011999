import { MAX_TSID_LENGTH } from "constants/index";
import { translationKeys as keys } from "./keys";

export const translations = {
  spanish: {
    [keys.faxConfiguration]: "Configuración del fax",
    [keys.advancedOptions]: "Opciones Avanzadas",
    [keys.priority]: "Prioridad",
    [keys.urgent]: "Urgente",
    [keys.normal]: "Normal",
    [keys.resolution]: "Resolución",
    [keys.standard]: "Estándar",
    [keys.fine]: "Alta",
    [keys.toLabel]: "Enviar fax a: (separar por punto y coma)",
    [keys.toPlaceholder]: "12345678; 12345678; ...",
    [keys.ccLabel]: "Incluir cc: (separar por punto y coma)",
    [keys.ccPlaceholder]: "12345678; 12345678; ...",
    [keys.scheduleFax]: "Programar un fax",
    [keys.selectHour]: "Seleccione una hora",
    [keys.selectDate]: "Seleccione una fecha",
    [keys.sendReportTo]: "Enviar informe de estado a:",
    [keys.sendReportToPlaceholder]: "john.doe@retarus.com",
    [keys.sendReportToAdditional]:
      "Enviar informe de estado a una dirección adicional:",
    [keys.sendReportToAdditionalPlaceholder]: "john.doe@retarus.com",
    [keys.copyOfFax]: "Recibir una copia del fax en el reporte",
    [keys.onlyAttachments]: "Enviar solo archivos adjuntos",
    [keys.addReceiverList]: "Agregar lista de receptores",
    [keys.uploadButtonLabel]: "Subir lista de receptores",
    [keys.resetOptionsButton]: "Restablecer Opciones",
    [keys.useSemiColonsError]:
      "Por favor, use punto y coma para separar destinatarios",
    [keys.onlyNumbersError]: "Por favor, use solo números para destinatarios",
    [keys.validEmailAddressesError]:
      "Por favor, ingrese una dirección de correo electrónico válida",
    [keys.onlyTxtFilesError]: "Solo se permiten archivos con extensión .txt",
    [keys.failToSetToError]: "No se pudo establecer el 'para'",
    [keys.failToSetCcError]: "No se pudo establecer el 'cc'",
    [keys.optionsSetSuccessfully]: "Opciones Guardadas Correctamente",
    [keys.sendFaxTo]: "Enviar fax a",
    [keys.includeCc]: "Incluir cc",
    [keys.statusReportTo]: "Enviar reporte de estado a",
    [keys.statusReportToAdditional]:
      "Enviar reporte de estado a (dirección adicional)",
    [keys.receiveCopyOfFax]: "Recibir una copia del fax en el reporte",
    [keys.sendOnlyAttachments]: "Enviar solo archivos adjuntos",
    [keys.receiversList]: "Lista de receptores",
    [keys.scheduledDate]: "Fecha programada",
    [keys.modifyOptionsButton]: "Modificar Opciones",
    [keys.noRecipient]: "No hay destinatarios",
    [keys.yes]: "Sí",
    [keys.no]: "No",
    [keys.none]: "Ninguno",
    [keys.noDate]: "Sin fecha",
    [keys.coverPageLabel]: "Carátula",
    [keys.coverPagePlaceholder]: "Seleccione una carátula",
    [keys.costCenterLabel]: "Centro de Costos",
    [keys.costCenterPlaceholder]: "Seleccione el centro de costos",
    [keys.tsidLabel]: "ID del transmisor",
    [keys.tsidPlaceholder]: "Ingrese el ID del remitente",
    [keys.tsidLengthError]:
      "El ID del remitente debe tener " + MAX_TSID_LENGTH + " dígitos o menos",
    [keys.noCoverPage]: "Sin carátula",
    [keys.noCostCenter]: "Sin centro de costos",
    [keys.noTsid]: "Sin ID del transmisor",
    [keys.faxDetails]: "Detalles del fax",
    [keys.sender]: "Remitente",
    [keys.faxNumber]: "Número de fax",
    [keys.subject]: "Asunto",
    [keys.body]: "Cuerpo",
    [keys.attachments]: "Archivos adjuntos",
    [keys.emptyFileError]: "El archivo no puede estar vacío",
    [keys.reply]: "Responder",
    [keys.createNewFax]: "Crear Nuevo Fax",
    [keys.tokenError]:
      "Error al requerir el token de autenticatión. Intente nuevamente. Si el problema persiste, contacte a su administrador.",
    [keys.internalServerError]:
      "Error interno del servidor, por favor reintente más tarde.",
    [keys.loadingInfo]: "Cargando información del usuario ...",
    [keys.errorPageButtonText]: "Reintentar",
    [keys.errorPageWaitingButtonText]: "Esperando para reintentar",
    [keys.internetHeadersError]:
      "No se ha podido procesar su selección, por favor intente nuevamente",
    [keys.attachmentsError]:
      "No se ha podido procesar sus archivos adjuntos, por favor intente nuevamente",
  },
  english: {
    [keys.faxConfiguration]: "Fax Configuration",
    [keys.advancedOptions]: "Advanced Options",
    [keys.priority]: "Priority",
    [keys.urgent]: "Urgent",
    [keys.normal]: "Normal",
    [keys.resolution]: "Resolution",
    [keys.standard]: "Standard",
    [keys.fine]: "Fine",
    [keys.toLabel]: "Send fax to: (separate by semi-colon)",
    [keys.toPlaceholder]: "12345678; 12345678; ...",
    [keys.ccLabel]: "Include cc: (separate by semi-colon)",
    [keys.ccPlaceholder]: "12345678; 12345678; ...",
    [keys.scheduleFax]: "Schedule a fax",
    [keys.selectHour]: "Select an hour",
    [keys.selectDate]: "Select a date",
    [keys.sendReportTo]: "Send Status Report To:",
    [keys.sendReportToPlaceholder]: "john.doe@retarus.com",
    [keys.sendReportToAdditional]: "Send Status Report To Additional address:",
    [keys.sendReportToAdditionalPlaceholder]: "john.doe@retarus.com",
    [keys.copyOfFax]: "Receive copy of fax in status report",
    [keys.onlyAttachments]: "Send only attachments",
    [keys.addReceiverList]: "Add receiver list",
    [keys.uploadButtonLabel]: "Upload receiver list",
    [keys.resetOptionsButton]: "Reset Options",
    [keys.useSemiColonsError]: "Please use semi-colons to separate recipients",
    [keys.onlyNumbersError]: "Please use only numbers for recipients",
    [keys.validEmailAddressesError]: "Please enter a valid email address",
    [keys.onlyTxtFilesError]: "Only files with .txt extension are allowed",
    [keys.failToSetToError]: "Failed to set the 'to' address",
    [keys.failToSetCcError]: "Failed to set the 'cc' address",
    [keys.optionsSetSuccessfully]: "Options Set Successfully",
    [keys.sendFaxTo]: "Send fax to",
    [keys.includeCc]: "Include cc",
    [keys.statusReportTo]: "Status report to",
    [keys.statusReportToAdditional]: "Status report to (additional email)",
    [keys.receiveCopyOfFax]: "Receive copy of fax in status report",
    [keys.sendOnlyAttachments]: "Send only attachments",
    [keys.receiversList]: "Receiver list",
    [keys.scheduledDate]: "Scheduled date",
    [keys.modifyOptionsButton]: "Modify Options",
    [keys.noRecipient]: "No recipient",
    [keys.yes]: "Yes",
    [keys.no]: "No",
    [keys.none]: "None",
    [keys.noDate]: "No date",
    [keys.coverPageLabel]: "Cover Page",
    [keys.coverPagePlaceholder]: "Select a cover page",
    [keys.costCenterLabel]: "Cost Center",
    [keys.costCenterPlaceholder]: "Select a cost center",
    [keys.tsidLabel]: "Transmitting Subscriber ID",
    [keys.tsidPlaceholder]: "Enter subscriber ID",
    [keys.tsidLengthError]:
      "Subscriber ID must have " + MAX_TSID_LENGTH + " digits or less",
    [keys.noCoverPage]: "No cover page",
    [keys.noCostCenter]: "No cost center",
    [keys.noTsid]: "No transmitting subscriber ID",
    [keys.faxDetails]: "Fax Details",
    [keys.sender]: "Sender",
    [keys.faxNumber]: "Fax Number",
    [keys.subject]: "Subject",
    [keys.body]: "Body",
    [keys.attachments]: "Attachments",
    [keys.reply]: "Reply",
    [keys.emptyFileError]: "File cannot be empty",
    [keys.createNewFax]: "Create New Fax",
    [keys.tokenError]:
      "Failed to retrieve auth token. Please try again later. If the problem persists, please contact your administrator.",
    [keys.internalServerError]:
      "Internal Server Error, please try again later.",
    [keys.loadingInfo]: "Loading user information ...",
    [keys.errorPageButtonText]: "Retry",
    [keys.errorPageWaitingButtonText]: "Waiting to retry",
    [keys.internetHeadersError]:
      "Could not set options successfully, please try again",
    [keys.attachmentsError]: "Attachment could not be added, please try again",
  },
  french: {
    [keys.priority]: "Priorité",
    [keys.urgent]: "Urgent",
    [keys.normal]: "Normal",
    [keys.toLabel]: "Envoyer le fax à: (séparer par un point-virgule)",
    [keys.toPlaceholder]: "12345678; 12345678; ...",
    [keys.ccLabel]: "Inclure cc: (séparer par un point-virgule)",
    [keys.ccPlaceholder]: "12345678; 12345678; ...",
    [keys.scheduleFax]: "Planifier un fax",
  },
  german: {
    [keys.priority]: "Priorität",
    [keys.urgent]: "Dringend",
    [keys.normal]: "Normal",
    [keys.toLabel]: "Fax an: (durch Semikolon trennen)",
    [keys.toPlaceholder]: "12345678; 12345678; ...",
    [keys.ccLabel]: "Cc einbeziehen: (durch Semikolon trennen)",
    [keys.ccPlaceholder]: "12345678; 12345678; ...",
    [keys.scheduleFax]: "Fax planen",
  },
};
