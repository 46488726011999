import {
  Checkbox,
  DefaultButton,
  Pivot,
  PivotItem,
  Text,
  DatePicker,
  DayOfWeek,
  defaultDatePickerStrings,
  Dropdown,
  Stack,
  Toggle,
  TextField,
  initializeIcons,
  MessageBarType,
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import {
  HOURS,
  CUSTOM_HEADERS_KEY,
  INITIAL_VALUES,
  INITIAL_CONFIG_VALUES,
} from "constants/index";
import {
  validateEmail,
  setReceiverAddress,
  parseReceiverFaxNumber,
  handleFile,
  parseEmailAddresses,
  parseDateAndTime,
  validateTsid,
  processHeaderValues,
  removeCustomHeadersValues,
  setNotification,
  parseConfigurations,
} from "helpers/index";
import {
  stackTokens,
  globalStackTokens,
  stackStyles,
  labelStyle,
} from "./styles";
import { handleTranslation } from "translations/helper";
import { translationKeys as keys } from "translations/keys";
import Notification from "components/notifications/Notification";
import { ItokenType, IConfigurations } from "types";
import { getAuthToken, getTenantConfig } from "api/api";
import Progress from "components/spinner/Progress";
import ErrorPage from "components/errorPage/errorPage";

initializeIcons();
const toggleLabel = (text: string) => {
  return <div style={{ fontSize: 14, fontWeight: 600 }}>{text}</div>;
};

function ComposeTaskPane() {
  const [toAddress, setToAddress] = useState(INITIAL_VALUES.addressValues.to);
  const [ccAddress, setCcAddress] = useState(INITIAL_VALUES.addressValues.cc);
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [token, setToken] = useState<ItokenType>({});
  const [serverResponseError, setServerResponseError] = useState("");
  const [configs, setConfigs] = useState<IConfigurations>({
    ...INITIAL_CONFIG_VALUES,
  });
  const [configurations, setConfigurations] = useState<IConfigurations>({
    ...INITIAL_CONFIG_VALUES,
  });
  const [addressFields, setAddressFields] = useState(
    INITIAL_VALUES.addressValues
  );
  const [date, setDate] = useState(INITIAL_VALUES.date);
  const [file, setFile] = useState(INITIAL_VALUES.file);
  const [locale, setLocale] = useState(INITIAL_VALUES.locale);
  const [fileLoading, setFileLoading] = useState(false);
  const [tenantConfigurations, setTenantConfigurations] = useState({
    priority: true,
    resolution: true,
    schedule: true,
    reportTo: true,
    copyOfFax: true,
    additionalReportTo: true,
    coverPage: true,
    costCenter: true,
    tsid: true,
    addReceiverList: true,
    attachmentOnly: true,
  });
  const translate = useCallback(
    (key: string) => {
      return handleTranslation(key, locale);
    },
    [locale]
  );
  const [customHeadersValues, setCustomHeadersValues] = useState({});
  const [notification, setNotificationText] = useState({
    type: MessageBarType.info,
    message: "",
  });

  const handleChangeHeadersValues = (
    e: React.FormEvent<HTMLElement | HTMLInputElement>,
    headerValue?: string
  ) => {
    const { key, value } = processHeaderValues(e, headerValue);
    if (value.trim() === "") return;
    setCustomHeadersValues({
      ...customHeadersValues,
      [key]: value,
    });
  };

  const handleRemoveHeadersValues = (key: string) => {
    const newCustomHeadersValues = removeCustomHeadersValues(key, {
      ...customHeadersValues,
    });
    setCustomHeadersValues(newCustomHeadersValues);
  };

  function handleSelect(event: any, option: any) {
    setConfigs({
      ...configs,
      dropdownSelectedValues: {
        ...configs.dropdownSelectedValues,
        [event.target!.id]: option.key,
      },
    });
    if (event.target!.id === "costCenter" || event.target!.id === "coverPage") {
      handleChangeHeadersValues(event, option.text);
    }
    if (event.target!.id === "hour" && date) {
      handleDatePick(date, option.text);
    }
  }

  async function handleFileChange(event: any) {
    setFileLoading(true);
    try {
      const uploadedFile = await handleFile(event, file.name);
      if (uploadedFile.error)
        uploadedFile.error =
          translate(uploadedFile.error) || uploadedFile.error;
      setFile(uploadedFile);
      if (!uploadedFile.error) return addAttachment(uploadedFile);
    } catch (error) {
      setFile({
        name: "",
        content: "",
        error: "Could not upload file",
      });
    } finally {
      setFileLoading(false);
    }
  }

  function restoreEvent(event: any) {
    event.target.value = "";
  }

  const handleChangeFields = (
    e: React.FormEvent<HTMLElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    let validation = "";
    if (name === "tsid") {
      validation = validateTsid(value);
    } else {
      validation = validateEmail(name, value);
    }

    return setConfigs({
      ...configs,
      fieldValues: {
        ...configs.fieldValues,
        [name]: {
          value: value,
          error: translate(validation),
        },
      },
    });
  };

  const handleChangeAddressFields = (
    e: React.FormEvent<HTMLElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    const validation = validateEmail(name, value);
    return setAddressFields({
      ...addressFields,
      [name]: {
        value: value,
        error: translate(validation),
      },
    });
  };

  const formatEmails = (e: React.FormEvent<HTMLElement | HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    const emails = parseEmailAddresses(value);
    let validation = translate(validateEmail(name, value));
    if (!emails) validation = "";
    return setConfigs({
      ...configs,
      fieldValues: {
        ...configs.fieldValues,
        [name]: {
          value: emails,
          error: validation,
        },
      },
    });
  };

  const syncFields = (e: React.FormEvent<HTMLElement | HTMLInputElement>) => {
    const { name, value } = e.target as HTMLInputElement;
    const validation = validateEmail(name, value);
    if (name === "to" && !validation)
      return setReceiverAddress(value, "to", configs.sufix);
    if (name === "cc" && !validation)
      return setReceiverAddress(value, "cc", configs.sufix);
  };

  function handleDatePick(
    date: string | Date | null | undefined,
    hour?: string
  ) {
    const dateValue = date?.toString() || "";
    setDate(dateValue);

    const hourToSchedule =
      hour || HOURS[configs.dropdownSelectedValues.hour - 1].text;

    const fullDate = parseDateAndTime(dateValue, hourToSchedule);
    handleChangeHeadersValues({ target: { name: "date" } } as any, fullDate);
  }

  function removeAttachment(callback?: Function) {
    const item = Office.context.mailbox.item;
    item?.getAttachmentsAsync((result) => {
      const attachmentToBeRemoved = result.value.filter((attachment) => {
        return attachment.name === file.name;
      });
      if (attachmentToBeRemoved[0].id) {
        item.removeAttachmentAsync(attachmentToBeRemoved[0].id, (result) => {
          if (result.status === Office.AsyncResultStatus.Failed) {
            return setNotification(
              MessageBarType.error,
              "Could not remove attachment, please retry",
              setNotificationText
            );
          }
          if (callback) return callback();
        });
      } else {
        return setNotification(
          MessageBarType.error,
          "No attachment to be removed",
          setNotificationText
        );
      }
    });
  }

  function addAttachment(uploadedFile: any) {
    const item = Office.context.mailbox.item;
    const attachFile = () => {
      item?.addFileAttachmentFromBase64Async(
        uploadedFile.content,
        uploadedFile.name,
        { isInline: false },
        function (result) {
          if (result.status === Office.AsyncResultStatus.Failed) {
            return setNotification(
              MessageBarType.error,
              keys.attachmentsError,
              setNotificationText
            );
          }
        }
      );
    };
    if (file.name) return removeAttachment(attachFile);
    return attachFile();
  }

  function setCustomHeaders() {
    let internetHeadersValues = "";
    for (let key in customHeadersValues) {
      internetHeadersValues += `[${key}: ${
        customHeadersValues[key as keyof typeof customHeadersValues]
      }]`;
    }

    const internetHeaders = {
      [CUSTOM_HEADERS_KEY]: internetHeadersValues,
    };
    Office.context.mailbox.item?.internetHeaders?.setAsync(
      internetHeaders,
      setHeadersCallback
    );
  }

  function setHeadersCallback(asyncResult: any) {
    if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
      getCustomHeaders();
    } else {
      setNotification(
        MessageBarType.error,
        keys.internetHeadersError,
        setNotificationText
      );
    }
  }

  function getCustomHeaders() {
    Office.context.mailbox.item?.internetHeaders?.getAsync(
      [CUSTOM_HEADERS_KEY],
      (asyncResult) => {
        setNotification(
          MessageBarType.success,
          asyncResult.value[CUSTOM_HEADERS_KEY],
          setNotificationText
        );
      }
    );
  }

  const itemChanged = () => {
    const locale =
      Office.context.contentLanguage ||
      Office.context.displayLanguage ||
      "en-US";
    resetOptions();
    getToken(locale);
    Office.context.mailbox.item?.addHandlerAsync(
      Office.EventType.RecipientsChanged,
      recipientsChanged
    );
  };

  function resetOptions() {
    setConfigs(configurations);
    setAddressFields(INITIAL_VALUES.addressValues);
    setFile(INITIAL_VALUES.file);
    setDate(INITIAL_VALUES.date);
    removeAttachment();
    setCustomHeadersValues({});
    setReceiverAddress("", "to", "");
    setReceiverAddress("", "cc", "");
  }

  const handleCheckboxChanges = (e: any) => {
    // set checkboxes values
    if (e.target.name === "addReceiverList" && !e.target.checked) {
      setFile({ name: "", content: "", error: "" });
      removeAttachment();
    }
    if (e.target.name === "faxCopy" || e.target.name === "onlyAtt") {
      if (
        configs.checkBoxValues[
          e.target.name as keyof typeof configs.checkBoxValues
        ]
      ) {
        handleChangeHeadersValues(e, "OFF");
      } else {
        handleChangeHeadersValues(e, "ON");
      }
    }
    // handle dropdown´s checkboxes behaviour
    let key = e.target.name;
    let value: string | number = "";
    if (
      e.target.name === "date" ||
      e.target.name === "coverPage" ||
      e.target.name === "costCenter"
    ) {
      if (!e.target.checked) {
        handleRemoveHeadersValues(e.target.name);
        if (e.target.name === "date") {
          setDate("");
          key = "hour";
          value = 0;
        }
      }
    }

    return setConfigs({
      ...configs,
      checkBoxValues: {
        ...configs.checkBoxValues,
        [e.target.name]:
          !configs.checkBoxValues[
            e.target.name as keyof typeof configs.checkBoxValues
          ],
      },
      dropdownSelectedValues: {
        ...configs.dropdownSelectedValues,
        [key]: value,
      },
    });
  };

  function handleToggle(event: any, checked?: boolean) {
    const toggle = event.target.id;
    if (toggle === "priority") {
      if (checked) {
        setConfigs({
          ...configs,
          priority: "Urgent",
        });
        return handleChangeHeadersValues(event, "URGENT");
      } else {
        setConfigs({
          ...configs,
          priority: "Normal",
        });
        return handleChangeHeadersValues(event, "NORMAL");
      }
    }

    if (toggle === "resolution") {
      if (checked) {
        setConfigs({
          ...configs,
          resolution: "Fine",
        });
        return handleChangeHeadersValues(event, "FINE");
      } else {
        setConfigs({
          ...configs,
          resolution: "Standard",
        });
        return handleChangeHeadersValues(event, "STANDARD");
      }
    }
  }

  const syncAdressValues = (
    toReceivers: string,
    ccReceivers: string,
    toError: string,
    ccError: string
  ) => {
    setAddressFields({
      cc: {
        ...addressFields.cc,
        value: ccReceivers,
        error: translate(ccError),
      },
      to: {
        ...addressFields.to,
        value: toReceivers,
        error: translate(toError),
      },
    });
  };

  function getParsedField(to: string, cc: string) {
    const item = Office.context.mailbox.item;
    let toReceivers = "";
    let toValidation = "";
    let validation = "";
    let ccReceivers = "";

    item?.to.getAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        toReceivers = parseReceiverFaxNumber(result.value);
        toValidation = validateEmail("to", toReceivers);
        setToAddress({ ...toAddress, value: toReceivers });
        return syncAdressValues(
          toReceivers,
          ccReceivers,
          toValidation,
          validation
        );
      }
    });

    item?.cc.getAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        ccReceivers = parseReceiverFaxNumber(result.value);
        validation = validateEmail("cc", ccReceivers);
        setCcAddress({ ...ccAddress, value: ccReceivers });
        return syncAdressValues(
          toReceivers,
          ccReceivers,
          toValidation,
          validation
        );
      }
    });
  }

  const recipientsChanged = (event: {
    changedRecipientFields: { to: boolean; cc: boolean; bcc: boolean };
  }) => {
    const { to, cc } = event.changedRecipientFields;
    if (to) return getParsedField("to", "");
    if (cc) return getParsedField("", "cc");
  };

  function syncInternetHeaders(e: any, validator: Function, name?: string) {
    let error = "";
    if (name) {
      error = validator(name, e.target.value);
    } else {
      error = validator(e.target.value);
    }
    if (!error && e.target.value.trim()) handleChangeHeadersValues(e);
    if (error || !e.target.value) handleRemoveHeadersValues(e.target.name);
  }

  const getToken = async (locale: string) => {
    setIsLoadingConfig(true);

    const azureToken: ItokenType = await getAuthToken();
    if (azureToken.error) {
      setToken(azureToken);
      setNotification(
        MessageBarType.error,
        azureToken.error,
        setNotificationText
      );
    }
    if (azureToken.token) {
      const serverResponse = await getTenantConfig(azureToken.token, locale);
      if (serverResponse) {
        if (serverResponse.error) {
          setServerResponseError(serverResponse.error);
          setIsLoadingConfig(false);
          return setNotification(
            MessageBarType.error,
            serverResponse.error,
            setNotificationText
          );
        }
        const { configurations } = serverResponse;
        const initialConfigs = parseConfigurations(configurations);
        setConfigurations(initialConfigs);
        setTenantConfigurations({
          priority: configurations.priority.display,
          resolution: configurations.resolution.display,
          copyOfFax: configurations.sndStatusMail.display,
          coverPage: configurations.coverPage.display,
          costCenter: configurations.costCenter.display,
          attachmentOnly: configurations.attOnly.display,
          addReceiverList: configurations.rcptList.display,
          schedule: configurations.scheduleFax.display,
          reportTo: configurations.statusMailTo.display,
          additionalReportTo: configurations.statusMailCC.display,
          tsid: configurations.tsid.display,
        });
        setConfigs(initialConfigs);
      }
    }
    setIsLoadingConfig(false);
  };

  useEffect(() => {
    const locale =
      Office.context.contentLanguage ||
      Office.context.displayLanguage ||
      "en-US";
    setLocale(locale);
    getToken(locale);

    Office.context.mailbox.addHandlerAsync(
      Office.EventType.ItemChanged,
      itemChanged
    );
    Office.context.mailbox.item?.addHandlerAsync(
      Office.EventType.RecipientsChanged,
      recipientsChanged
    );
    getParsedField("to", "cc");
  }, []);

  useEffect(() => {
    setCustomHeaders();
  }, [customHeadersValues]);

  useEffect(() => {
    const validation = validateEmail("to", toAddress.value);
    if (!validation && toAddress.value) {
      setReceiverAddress(toAddress.value, "to", configs.sufix);
    }
  }, [toAddress.value, configs.sufix]);

  useEffect(() => {
    const validation = validateEmail("cc", ccAddress.value);
    if (!validation && ccAddress.value) {
      setReceiverAddress(ccAddress.value, "cc", configs.sufix);
    }
  }, [ccAddress.value, configs.sufix]);

  return (
    <>
      {Boolean(isLoadingConfig) && (
        <Progress message={translate(keys.loadingInfo)} />
      )}
      {Boolean(notification.message) && (
        <Notification
          type={notification.type}
          message={translate(notification.message) || notification.message}
        />
      )}
      <div className='App'>
        {Boolean(!isLoadingConfig && !token.error && !serverResponseError) && (
          <Pivot aria-label='Menu buttons'>
            {/* FAX CONFIGURATION VIEW */}
            <PivotItem headerText={translate(keys.faxConfiguration)}>
              <Stack tokens={globalStackTokens} styles={stackStyles}>
                <TextField
                  value={addressFields.to.value}
                  name='to'
                  id='to'
                  label={translate(keys.toLabel)}
                  placeholder={translate(keys.toPlaceholder)}
                  onChange={(e) => handleChangeAddressFields(e)}
                  onBlur={(e) => syncFields(e)}
                  errorMessage={addressFields.to.error || ""}
                  prefix='+'
                  suffix={configs.sufix}
                />
                <TextField
                  value={addressFields.cc.value}
                  name='cc'
                  id='cc'
                  label={translate(keys.ccLabel)}
                  placeholder={translate(keys.ccPlaceholder)}
                  onChange={(e) => handleChangeAddressFields(e)}
                  onBlur={(e) => syncFields(e)}
                  errorMessage={addressFields.cc.error || ""}
                  prefix='+'
                  suffix={configs.sufix}
                />
                <Stack
                  tokens={stackTokens}
                  horizontal
                  style={{ columnGap: "3rem" }}>
                  {tenantConfigurations.priority && (
                    <Stack style={{ width: "8rem" }}>
                      <Toggle
                        checked={configs.priority === "Urgent" ? true : false}
                        label={toggleLabel(translate(keys.priority))}
                        onText={translate(keys.urgent)}
                        offText={translate(keys.normal)}
                        id='priority'
                        onChange={handleToggle}
                      />
                    </Stack>
                  )}
                  {tenantConfigurations.resolution && (
                    <Stack style={{ width: "8rem" }}>
                      <Toggle
                        checked={configs.resolution === "Fine" ? true : false}
                        label={toggleLabel(translate(keys.resolution))}
                        onText={translate(keys.fine)}
                        offText={translate(keys.standard)}
                        onChange={handleToggle}
                        id='resolution'
                      />
                    </Stack>
                  )}
                </Stack>

                {tenantConfigurations.coverPage && (
                  <Stack tokens={stackTokens}>
                    <Checkbox
                      label={translate(keys.coverPageLabel)}
                      ariaLabel='Cover Page'
                      boxSide='start'
                      styles={labelStyle}
                      name='coverPage'
                      checked={configs.checkBoxValues.coverPage}
                      onChange={(e) => handleCheckboxChanges(e)}
                    />
                    {configs.checkBoxValues.coverPage && (
                      <Dropdown
                        selectedKey={
                          configs.dropdownSelectedValues.coverPage ?? undefined
                        }
                        placeholder={translate(keys.coverPagePlaceholder)}
                        options={configs.dropdownOptions.coverPage || []}
                        id='coverPage'
                        aria-label='coverPage'
                        onChange={handleSelect}
                        disabled={!configs.checkBoxValues.coverPage}
                      />
                    )}
                  </Stack>
                )}

                {tenantConfigurations.costCenter && (
                  <Stack tokens={stackTokens}>
                    <Checkbox
                      label={translate(keys.costCenterLabel)}
                      ariaLabel='Cost Center'
                      boxSide='start'
                      styles={labelStyle}
                      name='costCenter'
                      checked={configs.checkBoxValues.costCenter}
                      onChange={(e) => handleCheckboxChanges(e)}
                    />
                    {configs.checkBoxValues.costCenter && (
                      <Dropdown
                        selectedKey={
                          configs.dropdownSelectedValues.costCenter ?? undefined
                        }
                        placeholder={translate(keys.costCenterPlaceholder)}
                        options={configs.dropdownOptions.costCenter || []}
                        id='costCenter'
                        aria-label='costCenter'
                        onChange={handleSelect}
                        disabled={!configs.checkBoxValues.costCenter}
                      />
                    )}
                  </Stack>
                )}
                <Stack
                  style={{ marginTop: "1rem" }}
                  horizontal
                  horizontalAlign='center'>
                  <DefaultButton
                    onClick={() => resetOptions()}
                    style={{ width: "100%" }}>
                    {translate(keys.resetOptionsButton)}
                  </DefaultButton>
                </Stack>
              </Stack>
            </PivotItem>

            {/* ADVANCED OPTIONS */}
            {(tenantConfigurations.attachmentOnly ||
              tenantConfigurations.copyOfFax ||
              tenantConfigurations.addReceiverList ||
              tenantConfigurations.schedule ||
              tenantConfigurations.reportTo ||
              tenantConfigurations.additionalReportTo ||
              tenantConfigurations.tsid) && (
              <PivotItem headerText={translate(keys.advancedOptions)}>
                <Stack
                  tokens={globalStackTokens}
                  styles={stackStyles}
                  style={{ paddingBottom: "5px" }}>
                  {tenantConfigurations.attachmentOnly && (
                    <Checkbox
                      label={translate(keys.onlyAttachments)}
                      ariaLabel={translate(keys.onlyAttachments)}
                      boxSide='start'
                      styles={labelStyle}
                      name='onlyAtt'
                      checked={configs.checkBoxValues.onlyAtt}
                      onChange={(e) => handleCheckboxChanges(e)}
                    />
                  )}

                  {tenantConfigurations.copyOfFax && (
                    <Checkbox
                      label={translate(keys.copyOfFax)}
                      ariaLabel={translate(keys.copyOfFax)}
                      boxSide='start'
                      styles={labelStyle}
                      name='faxCopy'
                      checked={configs.checkBoxValues.faxCopy}
                      onChange={(e) => handleCheckboxChanges(e)}
                    />
                  )}

                  {tenantConfigurations.addReceiverList && (
                    <Stack tokens={stackTokens}>
                      <Checkbox
                        label={translate(keys.addReceiverList)}
                        ariaLabel='Add receiver list'
                        boxSide='start'
                        styles={labelStyle}
                        name='addReceiverList'
                        checked={configs.checkBoxValues.addReceiverList}
                        onChange={(e) => handleCheckboxChanges(e)}
                      />
                      <label
                        style={{
                          border: !configs.checkBoxValues.addReceiverList
                            ? "1px solid rgb(243, 242, 241)"
                            : "1px solid black",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "0.5rem 0",
                          cursor: "pointer",
                          backgroundColor:
                            !configs.checkBoxValues.addReceiverList ||
                            fileLoading
                              ? "rgb(243, 242, 241)"
                              : "white",
                        }}>
                        <input
                          type={"file"}
                          onChange={(e) => handleFileChange(e)}
                          placeholder='Upload receiver list'
                          style={{ display: "none" }}
                          disabled={!configs.checkBoxValues.addReceiverList}
                          onClick={restoreEvent}
                        />
                        <Text
                          className='file-upload__label'
                          style={{
                            color:
                              !configs.checkBoxValues.addReceiverList ||
                              fileLoading
                                ? "rgb(161, 159,157"
                                : "black",
                          }}>
                          {fileLoading
                            ? "Uploading file ..."
                            : translate(keys.uploadButtonLabel)}
                        </Text>
                      </label>
                      <Text
                        style={{
                          fontSize: "0.8rem",
                          color: file.error ? "red" : "gray",
                        }}>
                        {file.error || file.name}
                      </Text>
                    </Stack>
                  )}

                  {tenantConfigurations.schedule && (
                    <Stack tokens={stackTokens}>
                      <Checkbox
                        label={translate(keys.scheduleFax)}
                        ariaLabel='Schedule a fax'
                        boxSide='start'
                        styles={labelStyle}
                        name='date'
                        checked={configs.checkBoxValues.date}
                        onChange={(e) => handleCheckboxChanges(e)}
                      />

                      {Boolean(configs.checkBoxValues.date) && (
                        <Stack
                          tokens={stackTokens}
                          horizontal
                          style={{ width: "100%" }}>
                          <Dropdown
                            selectedKey={
                              configs.dropdownSelectedValues.hour ?? undefined
                            }
                            placeholder={translate(keys.selectHour)}
                            options={HOURS}
                            onChange={handleSelect}
                            id='hour'
                            disabled={Boolean(!configs.checkBoxValues.date)}
                          />

                          <DatePicker
                            firstDayOfWeek={DayOfWeek.Monday}
                            showWeekNumbers={false}
                            placeholder={translate(keys.selectDate)}
                            ariaLabel='Select a date'
                            strings={defaultDatePickerStrings}
                            onSelectDate={(date) => handleDatePick(date)}
                            value={date ? new Date(date) : undefined}
                            minDate={new Date()}
                            style={{ width: "100%" }}
                            disabled={Boolean(
                              !configs.dropdownSelectedValues.hour
                            )}
                          />
                        </Stack>
                      )}
                    </Stack>
                  )}
                  {tenantConfigurations.reportTo && (
                    <TextField
                      value={configs.fieldValues.reportTo.value}
                      name='reportTo'
                      label={translate(keys.sendReportTo)}
                      placeholder={translate(keys.sendReportToPlaceholder)}
                      onChange={(
                        e: React.FormEvent<HTMLElement | HTMLInputElement>
                      ) => handleChangeFields(e)}
                      onBlur={(e) => {
                        formatEmails(e);
                        syncInternetHeaders(e, validateEmail, e.target.name);
                      }}
                      errorMessage={configs.fieldValues.reportTo.error || ""}
                    />
                  )}
                  {tenantConfigurations.additionalReportTo && (
                    <TextField
                      name='additionalReport'
                      value={configs.fieldValues.additionalReport.value}
                      label={translate(keys.sendReportToAdditional)}
                      placeholder={translate(
                        keys.sendReportToAdditionalPlaceholder
                      )}
                      onChange={(
                        e: React.FormEvent<HTMLElement | HTMLInputElement>
                      ) => handleChangeFields(e)}
                      onBlur={(e) => {
                        formatEmails(e);
                        syncInternetHeaders(e, validateEmail, e.target.name);
                      }}
                      errorMessage={
                        configs.fieldValues.additionalReport.error || ""
                      }
                    />
                  )}

                  {tenantConfigurations.tsid && (
                    <Stack tokens={stackTokens}>
                      <TextField
                        value={configs.fieldValues.tsid.value}
                        name='tsid'
                        id='tsid'
                        label={translate(keys.tsidLabel)}
                        placeholder={translate(keys.tsidPlaceholder)}
                        onChange={(e) => handleChangeFields(e)}
                        onBlur={(e) => {
                          syncInternetHeaders(e, validateTsid);
                        }}
                        errorMessage={configs.fieldValues.tsid.error || ""}
                      />
                    </Stack>
                  )}
                </Stack>
              </PivotItem>
            )}
          </Pivot>
        )}
        {Boolean(token.error) && (
          <ErrorPage
            message={translate(token.error!) || token.error}
            retry
            buttonText={translate(keys.errorPageButtonText)}
            waitingButtonText={translate(keys.errorPageWaitingButtonText)}
          />
        )}
        {Boolean(serverResponseError) && (
          <ErrorPage
            message={translate(serverResponseError) || serverResponseError}
            buttonText={translate(keys.errorPageButtonText)}
            waitingButtonText={translate(keys.errorPageWaitingButtonText)}
          />
        )}
      </div>
    </>
  );
}

export default ComposeTaskPane;
