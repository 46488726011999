import {
  PrimaryButton,
  Pivot,
  PivotItem,
  Text,
  Stack,
  IStackStyles,
  IStackTokens,
  MessageBarType,
  initializeIcons,
} from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { handleTranslation } from "translations/helper";
import { translationKeys as keys } from "translations/keys";
import Notification from "components/notifications/Notification";
import { setNotification } from "helpers";
import api from "api/api";
import { ItokenType } from "types";
import Progress from "components/spinner/Progress";
import ErrorPage from "components/errorPage/errorPage";

const stackTokens: IStackTokens = { childrenGap: 6 };
const globalStackTokens: IStackTokens = { childrenGap: 14 };
const stackStyles: Partial<IStackStyles> = {
  root: { padding: "1rem" },
};
const backgroundStackStyles: Partial<IStackStyles> = {
  root: { padding: "1rem", backgroundColor: "#f4f4f4" },
};

initializeIcons();

function ReadTaskPane() {
  const [sender, setSender] = useState({
    displayName: "",
    emailAddress: "",
  });
  const [subject, setSubject] = useState("");
  const [attachments, setAttachments] = useState<string[]>([]);
  const [serverResponseError, setServerResponseError] = useState("");
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [token, setToken] = useState<ItokenType>({ token: "", error: "" });
  const [locale, setLocale] = useState("en-US");
  const [notification, setNotificationText] = useState({
    type: MessageBarType.info,
    message: "",
  });

  const translate = useCallback(
    (key: string) => {
      return handleTranslation(key, locale);
    },
    [locale]
  );

  const createFaxReply = () => {
    Office.context.mailbox.item?.displayReplyForm({
      htmlBody: `<p>Thank you for your fax.</p>`,
    });
  };

  const createNewFax = () => {
    Office.context.mailbox.displayNewMessageFormAsync(
      {},
      function (asyncResult: any) {
        return;
      }
    );
  };

  const getToken = async (locale: string) => {
    setIsLoadingConfig(true);
    const azureToken: ItokenType = await api.getAuthToken();
    console.log("azureToken", azureToken);
    setToken(azureToken);
    if (azureToken.error) {
      setToken(azureToken);
      setNotification(
        MessageBarType.error,
        azureToken.error,
        setNotificationText
      );
    }
    if (azureToken.token) {
      const serverResponse = await api.getTenantConfig(
        azureToken.token,
        locale
      );
      console.log("serverResponse", serverResponse);
      if (serverResponse) {
        if (serverResponse.error) {
          setServerResponseError(serverResponse.error);
          setIsLoadingConfig(false);
          return setNotification(
            MessageBarType.error,
            serverResponse.error,
            setNotificationText
          );
        }
      }
    }
    setIsLoadingConfig(false);
  };

  function setFaxData() {
    const item = Office.context?.mailbox?.item;
    if (item) {
      setSender(item.sender);
      setSubject(item.subject);
      if (item.attachments.length > 0) {
        const attachmentList = item.attachments.map(
          (attachment: any) => attachment.name
        );
        setAttachments(attachmentList);
      } else {
        setAttachments([]);
      }
    }
  }

  const itemChanged = () => {
    setFaxData();
  };

  useEffect(() => {
    setFaxData();
    const locale =
      Office.context.contentLanguage ||
      Office.context.displayLanguage ||
      "en-US";
    setLocale(locale);
    getToken(locale);

    Office.context.mailbox.addHandlerAsync(
      Office.EventType.ItemChanged,
      itemChanged
    );
  }, []);

  return (
    <>
      {Boolean(isLoadingConfig) && (
        <Progress message={translate(keys.loadingInfo)} />
      )}
      {Boolean(notification.message) && (
        <Notification
          type={notification.type}
          message={translate(notification.message) || notification.message}
        />
      )}
      <div className='App'>
        {Boolean(token.token && !serverResponseError && !isLoadingConfig) && (
          <Pivot aria-label='Menu buttons'>
            <PivotItem headerText={translate(keys.faxDetails)}>
              <Stack tokens={globalStackTokens} styles={stackStyles}>
                <Stack tokens={stackTokens}>
                  <Text>
                    <b>{translate(keys.sender)}:</b> {sender.displayName}
                  </Text>

                  <Text>
                    <b>{translate(keys.faxNumber)}:</b> {sender.emailAddress}
                  </Text>
                  <Text>
                    <b>{translate(keys.subject)}:</b>{" "}
                    {subject || translate(keys.none)}
                  </Text>
                  <Text>
                    <b>{translate(keys.attachments)}:</b>{" "}
                    {attachments.length > 0 ? (
                      <ul>
                        {attachments.map((attachment) => (
                          <li key={attachment}>{attachment}</li>
                        ))}
                      </ul>
                    ) : (
                      translate(keys.none)
                    )}
                  </Text>
                </Stack>
                <Stack tokens={stackTokens} styles={backgroundStackStyles}>
                  <PrimaryButton
                    onClick={createFaxReply}
                    style={{ minWidth: 150 }}>
                    {translate(keys.reply)}
                  </PrimaryButton>
                  <PrimaryButton
                    onClick={createNewFax}
                    style={{ minWidth: 150 }}>
                    {translate(keys.createNewFax)}
                  </PrimaryButton>
                </Stack>
              </Stack>
            </PivotItem>
          </Pivot>
        )}
        {Boolean(token && token.error) && (
          <ErrorPage
            message={translate(token.error!) || token.error}
            retry
            buttonText={translate(keys.errorPageButtonText)}
            waitingButtonText={translate(keys.errorPageWaitingButtonText)}
          />
        )}
        {Boolean(serverResponseError) && (
          <ErrorPage
            message={translate(serverResponseError) || serverResponseError}
            buttonText={translate(keys.errorPageButtonText)}
            waitingButtonText={translate(keys.errorPageWaitingButtonText)}
          />
        )}
      </div>
    </>
  );
}

export default ReadTaskPane;
